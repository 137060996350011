<template>
  <div class="product_box">
    <div class="pro_info flex-c-s-s">
      <van-image :style="{ opacity: myinfo.releaseFlag === 2? '0.3' :'1' }" width="100%" height="100%"
                 :src="myinfo.saleIconUrl" @click="jumpDetail(myinfo.productId)"
      />
      <!-- 下架 -->
      <div v-if="myinfo.releaseFlag === 2" class="pro_sale_out">
        <van-image width="1.39rem" height="1.39rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/sale_out.png"
        />
      </div>
      <div v-if="myinfo.releaseFlag === 3" class="presell_pro" style="width: 100%;height: 0.64rem;">
        <van-image width="100%" height="0.64rem" style="border-radius: 0;"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_pro.png"
        />
      </div>
      <div class="pro_name van-multi-ellipsis--l2" style="margin-top: 0.2rem;" @click="jumpDetail(myinfo.productId)">
        <span v-if="myinfo.isStrict" class="vip_pro_tag">严选</span>
        {{ myinfo.saleName }}
      </div>
      <div class="pro_brand_model van-ellipsis" @click="jumpDetail(myinfo.productId)">{{ myinfo.proBrand }} |
        {{ myinfo.proModel }}</div>
      <div class="pro_bottom flex-r-sb-c">
        <div class="pro_money">￥<span
          style="font-size: 0.45rem;"
        >{{ myinfo.showPrice === 0? myinfo.salePrice :'详询客服' }}</span></div>
        <div class="pro_btn flex-r-c-c" style="margin-right:0.2rem">
          <van-stepper v-if="myinfo.num > 0" v-model="myinfo.num" :step="myinfo.minQuantity" :min="0" integer
                       input-width="0.7rem" button-size="0.5rem" theme="round" @change="changeNum"
          />
          <van-icon v-else name="add" color="#0767AB" size="0.55rem" @click="addNum" />
        </div>
      </div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
import { mallPreferential_addCart, mallPreferential_delCart } from '@/api/pro'
export default {
  components: { kefuBtn },
  props: {
    info: { type: Object, default: () => { } },
    coming: { type: String, default: 'list' },
    cannum: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info,
      mycoming: this.coming,
      mycanBuyNum: this.cannum === -1 ? 9999 : this.cannum
    }
  },
  methods: {

    // 进入详情
    jumpDetail(productId) {
      sessionStorage.setItem('classify_pro_id', 'pro_' + this.id)
      this.$router.push('/product/detail_cart?productId=' + productId)
    },
    addNum() {
      mallPreferential_addCart({ productId: this.myinfo.productId, num: parseInt(this.myinfo.minQuantity) }).then(res => {
        this.myinfo.num = parseInt(this.myinfo.minQuantity)
        this.$emit('changeNum', res.data)
      })
    },
    // 修改数量
    changeNum(val) {
      var minQuantity = parseInt(val) % parseInt(this.myinfo.minQuantity) === 0
      console.log(minQuantity)
      if (parseInt(val) > 0 && minQuantity) {
        mallPreferential_addCart({ productId: this.myinfo.productId, num: val }).then(res => {
          this.$emit('changeNum', res.data)
        })
      } else {
        mallPreferential_delCart({ productId: this.myinfo.productId }).then(res => { this.$emit('changeNum', res.data) })
        this.myinfo.num = 0
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style>
.pro_btn .van-stepper--round .van-stepper__minus {
  border: 0.03rem solid #e2e2e2;
  color: #000;
}
.pro_btn .van-stepper--round .van-stepper__plus {
  background-color: rgba(7, 103, 171, 1);
}
</style>
